import React from 'react';

const Copyright = () => {
  return (
    <div className='border-t border-white/20' style={{ backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
      <div className='container mx-auto'>
        <div className='pt-6 pb-8'>
          <p className='text-base text-center text-gray-300'>
            Copyright &copy; 2023 Nori Restaurant, Designed by
            <a className='text-orange-600' target='_blank' href='https://founder-technology.com/'> Founder Technology</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
