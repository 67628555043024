import React, { useEffect, useRef } from "react";

const ITEM_WIDTH = window.innerWidth < 576 ? 150 : 300;
const ITEM_HEIGHT = window.innerWidth < 576 ? 200 : 400 ;
const PADDING_X = 10;

const PhotoScroll = (props) => {

  var selectedIndex = useRef(-1);
  var el = useRef(null);

  var doSelect = function (targetIndex) {
    var _a, _b;
    var children = (_a = el === null || el === void 0 ? void 0 : el.current) === null || _a === void 0 ? void 0 : _a.children;
    if ((selectedIndex === null || selectedIndex === void 0 ? void 0 : selectedIndex.current) !== -1) {
      var currentEl = children === null || children === void 0 ? void 0 : children[selectedIndex === null || selectedIndex === void 0 ? void 0 : selectedIndex.current];
      currentEl === null || currentEl === void 0 ? void 0 : currentEl.style.transform = 'scale(1)';
      currentEl === null || currentEl === void 0 ? void 0 : currentEl.style.zIndex = '0';
    }
    var targetEl = children[targetIndex];
    if (!targetEl)
      return;
    targetEl === null || targetEl === void 0 ? void 0 : targetEl.style.transform = 'scale(1.4)';
    targetEl === null || targetEl === void 0 ? void 0 : targetEl.style.zIndex = '999';
    selectedIndex.current = targetIndex;
    // Update container position
    var theWidth = ITEM_WIDTH + PADDING_X;
    (_b = el === null || el === void 0 ? void 0 : el.current) === null || _b === void 0 ? void 0 : _b.style.transform = "translateX(calc(50% - ".concat(theWidth * targetIndex + theWidth * 0.5, "px))");
  };

  var iseUseEffect = false;

  function nextImage() {
    if (props?.imageData?.length != 0) {
      // console.log(selectedIndex?.current + 1);
      if (selectedIndex?.current == props?.imageData?.length - 1) doSelect(0)
      else doSelect(selectedIndex?.current + 1)
    }
  }

  useEffect(() => {
    if (!iseUseEffect) setInterval(nextImage, 3000)
    iseUseEffect = true
  }, []);

  useEffect(() => {
    doSelect(4);
  }, []);

  const renderedList = props?.imageData.map((it, key) => (
    <div
      className="max-w-50 absolute sahdotransition-all duration-700 ease-out origin-center rounded-lg bg-no-repeat bg-contain bg-center cursor-pointer"
      key={key}
      onClick={e => doSelect(key)}
      style={
        {
          width: ITEM_WIDTH,
          height: ITEM_HEIGHT,
          boxShadow: '2px 0px 0px -6px rgba(0,0,0,0.85)',
          left: (ITEM_WIDTH + PADDING_X) * key,
          backgroundImage: `url(${process.env.REACT_APP_URL + "storage/" + it?.img})`
        }}
    />
  ))

  return (
    <div className="relative transition-all duration-700 w-screen h-full my-0" ref={el}>
      {renderedList}
    </div>
  )
}

export default PhotoScroll
