import React from 'react';
// import components
import Newsletter from './Newsletter';
import Copyright from './Copyright';
import Socials from './Socials';
// import data
import { footerData } from '../data';
// import motion
import { motion } from 'framer-motion';
// import variants
import { fadeIn, staggerContainer } from '../variants';
// import Copyright from './Copyright';

const Footer = () => {
  // destructure footer data
  const { contact, hours, social } = footerData;
  const handelClickMap = () => window.open('https://goo.gl/maps/tvhwDBsaotyYLMeV6', '_blank');

  return (
    <footer className='relative pt-12 pb-12' style={{ backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="text-3xl font-bold text-orange-600 mb-4">LOCATION</h2>
        <p className="text-gray-300 mb-8">8, Rue D'ifrane, CIL - Casablanca, Maroc</p>
        <button onClick={handelClickMap} className="bg-orange-600 text-white rounded-xl py-1 px-10 hover:bg-orange-400 focus:outline-none focus:ring">Maps</button>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-between lg:justify-start lg:text-left h-full px-5 lg:px-20 pt-10">
        <div>
          <div className="text-white text-center mb-8 lg:mb-0">
            <h3 className="text-gray-300 mb-2 text-2xl">Contact Location</h3>
            <p className="text-white mb-2 text-grey">8, Rue D'ifrane, CIL - Casablanca, Maroc</p>
            <p className="text-white text-grey">05-22-39-55-55</p>
            <p className="text-white text-grey">05-22-39-32-22</p>
          </div>
        </div>
        <div className="text-white text-center lg:ml-auto">
          <h3 className="text-gray-300 mb-2 text-2xl">Working Hours</h3>
          <p className="text-orange-700 mb-2">DU LUNDI AU SAMEDI DE 12H00 À 15H00 ET DE 19H00 À 23H00</p>
          <p className="text-orange-700">LE DIMANCHE DE 12H00 À 23H00 NON STOP</p>
        </div>
      </div>
      {/* <Copyright /> */}
    </footer>
  );
};

export default Footer;
